import React from 'react';

import { IModelTemplateData } from '@/templates/model-2024';

type Props = {
  modelData: IModelTemplateData;
};

const MetricsRow = ({
  title,
  score,
  group,
}: {
  title: string;
  score: number;
  group: 'scr' | 'mcr' | 'lcr';
}) => (
  <div className="flex flex-col items-center justify-between gap-2 border-t border-gray-400/40 py-6 md:flex-row">
    <div className="md:w-[20%]">
      <p className="font-inter text-sm text-black">{title}</p>
    </div>
    <div className="w-full md:w-[65%]">
      <div
        className={`h-11 bg-hi-60 ${group === 'scr' ? 'opacity-60' : group === 'mcr' ? 'opacity-80' : group === 'lcr' ? 'opacity-100' : ''}`}
        style={{ width: `${score * 100}%` }}
      />
    </div>
    <div className="text-right md:w-[15%]">
      <p className="font-inter text-sm text-black">{score}</p>
    </div>
  </div>
);

const ModelPerformance = ({ modelData }: Props) => {
  return (
    <section className="mx-auto w-full max-w-[90rem] lg:px-[85px]">
      {/* Part with graphics */}
      <div className="flex flex-col gap-6 border border-b-0 border-gray-400/40 px-5 py-8 md:flex-row lg:py-10">
        <div className="md:w-[30%]">
          <h3 className="mb-5 font-inter text-2xl font-bold lg:text-[32px]">
            Model Performance Across Task-Types
          </h3>
        </div>

        <div className="border border-gray-400/40 p-4 md:w-[70%]">
          <div className="flex flex-row items-center justify-between py-2">
            <p className="ml-auto font-inter text-xs font-normal text-[#454547]">
              Chainpoll Score
            </p>
          </div>

          {modelData.scRag && !isNaN(modelData.scRag) ? (
            <MetricsRow
              title="Short Context"
              group="scr"
              score={modelData.scRag}
            />
          ) : null}

          {modelData.mcRag && !isNaN(modelData.mcRag) ? (
            <MetricsRow
              title="Medium Context"
              group="mcr"
              score={modelData.mcRag}
            />
          ) : null}
          {modelData.lcRag && !isNaN(modelData.lcRag) ? (
            <MetricsRow
              title="Long Context"
              group="lcr"
              score={modelData.lcRag}
            />
          ) : null}
        </div>
      </div>

      <div className="flex flex-col gap-6 border border-b-0 border-gray-400/40 px-5 py-8 lg:py-10">
        <h3 className="font-inter text-2xl font-bold lg:text-[32px]">
          Model Insights Across Task-Types
        </h3>
        <p className="font-inter text-lg text-hi-20 lg:text-[22px] lg:leading-9">
          Digging deeper, here’s a look how <strong>{modelData.name}</strong>{' '}
          performed across specific datasets
        </p>
      </div>
    </section>
  );
};

export default ModelPerformance;
