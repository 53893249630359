import React from 'react';

import { h2Style } from '../common';
import WavyBackgroundHeader from '../wave-backgroundHeader';
import { IModelTemplateData } from '@/templates/model-2024';

type Props = {
  modelData: IModelTemplateData;
};

const DetailsRow = ({
  leftText,
  rightText,
}: {
  leftText: string;
  rightText: string;
}) => (
  <div className="flex flex-row items-center justify-between gap-4 border-b border-gray-400/40 px-4 py-6">
    <p className="font-inter text-sm text-white">{leftText}</p>
    <p className="font-inter text-sm text-white">{rightText}</p>
  </div>
);

const ModelHero = ({ modelData }: Props) => {
  return (
    <section className="mx-auto w-full max-w-[90rem] pt-14 lg:px-[85px] lg:pt-20">
      <div className="w-full bg-smoky-black">
        {/* top section with animation */}
        <div className="flex flex-col md:flex-row">
          <div className="flex items-center border border-gray-400/40 px-5 py-4 md:w-[35%] md:border-r-0">
            <h2
              className={`${h2Style} !font-inter !font-bold leading-normal text-white lg:leading-[52px]`}
            >
              Model Insights
            </h2>
          </div>
          <div className="md:w-[65%]">
            <WavyBackgroundHeader className="w-full">
              <div className="grid w-full grid-cols-12">
                {new Array(36).fill(0).map((_, i) => (
                  <div
                    key={i}
                    className="col-span-1 h-14 border border-gray-400/40 bg-transparent"
                  />
                ))}
              </div>
            </WavyBackgroundHeader>
          </div>
        </div>

        {/* hero content */}
        <div className="flex flex-col md:flex-row">
          <div className="border-l border-r border-l-gray-400/40 border-r-gray-400/40 px-4 py-4 md:w-[60%] lg:pr-6">
            <h1 className="font-inter text-[40px] font-normal capitalize leading-normal text-white md:text-[52px] lg:text-[72px] lg:leading-[80px]">
              {modelData.name}
            </h1>
          </div>
          <div className="border-r border-r-gray-400/40 md:w-[40%]">
            <div className="border-b border-gray-400/40 px-4 py-8">
              <p className="text-sm uppercase tracking-[0.3rem] text-white">
                Details
              </p>
            </div>

            <DetailsRow leftText="Developer" rightText={modelData.developer} />
            <DetailsRow leftText="License" rightText={modelData.license} />
            <DetailsRow
              leftText="Model parameters"
              rightText={modelData.modelParameters}
            />
            <DetailsRow
              leftText="Supported context length"
              rightText={`${modelData.contextLength}k`}
            />
            <DetailsRow
              leftText="Price for prompt token"
              rightText={`$${modelData.pricePerToken}/Million tokens`}
            />
            <DetailsRow
              leftText="Price for response token"
              rightText={`$${modelData.responseCost}/Million tokens`}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ModelHero;
