import React from 'react';

import { IModelTemplateData } from '@/templates/model-2024';
import { h4Style } from '../common';
import {
  lcrSimpleHeatmapNames,
  mcrSimpleHeatmapNames,
} from '../model-data-helpers';

type Props = {
  modelData: IModelTemplateData;
  type: 'mcr' | 'lcr';
  title: string;
};

const McLcRag = ({ modelData, type, title }: Props) => {
  const heatmaps =
    type === 'lcr' ? lcrSimpleHeatmapNames : mcrSimpleHeatmapNames;

  return (
    <section className="mx-auto w-full max-w-[90rem] lg:px-[85px]">
      <div className="flex flex-col gap-4 border border-b-0 border-gray-400/40 px-5 py-8 lg:py-10">
        <h4 className={`${h4Style} !font-inter !font-medium`}>{title}</h4>
        <p className="font-inter text-base font-normal text-black">
          This heatmap indicates the model's success in recalling information at
          different locations in the context. Green signifies success, while red
          indicates failure.
        </p>
        <div className="w-full">
          <img
            alt={modelData.name}
            src={`/hi/${type}-simple/${heatmaps[modelData.name]}.png`}
            className="w-full"
          />
        </div>
      </div>
    </section>
  );
};

export default McLcRag;
