import React from 'react';

import RadarChart from '../index-page/radar-chart';
import { h4Style } from '../common';
import { IModelTemplateData } from '@/templates/model-2024';

type Props = {
  modelData: IModelTemplateData;
};

const ScRag = ({ modelData }: Props) => {
  return (
    <section className="mx-auto w-full max-w-[90rem] lg:px-[85px]">
      <div className="flex flex-col gap-6 border border-b-0 border-gray-400/40 px-5 py-8 md:flex-row lg:py-10">
        <div className="md:w-2/5">
          <h4 className={`${h4Style} !font-inter !font-medium`}>
            Short Context RAG
          </h4>
        </div>

        <div className="md:w-3/5">
          <RadarChart models={[modelData]} hideDropdown />
        </div>
      </div>
    </section>
  );
};

export default ScRag;
